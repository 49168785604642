import React from 'react'
import {graphql} from 'gatsby'
import Layout from './../components/layout'
import WorkSubNav from './../components/work-sub-nav'
import WorkSubHeading from './../components/work-sub-heading'
import GalleryGrid from './../components/gallery-grid'
// import {LIMITED_EDITION_PRINTS_IMAGES} from './../constants/limited-edition-prints-images'
import WorkIntroText from './../components/work-intro-text'
import WorkFooterText from './../components/work-footer-text'
import ScrollToTop from './../components/scroll-to-top'

export default props => {
  const {data} = props
  const prismicData = {
    buyNowButtonImageUrl: data.prismicLimitedEditionPrintsPage.data.buy_now__button_image_url.url,
    introText: data.prismicLimitedEditionPrintsPage.data.intro_text.html,
    footerText: data.prismicLimitedEditionPrintsPage.data.footer_text.html,
    pageHeading: data.prismicLimitedEditionPrintsPage.data.page_heading.text,
    limitedEditionPrintsAreAvailable: data.prismicLimitedEditionPrintsPage.data.currently_available,
    prints: data.prismicLimitedEditionPrintsPage.data.prints,
    purchaseEmailAddress: data.prismicLimitedEditionPrintsPage.data.email_address.text,
    purchaseEmailSubjectLine: data.prismicLimitedEditionPrintsPage.data.email_subject.text,
    purchaseMethod: data.prismicLimitedEditionPrintsPage.data.purchase_method,
  }

  const LIMITED_EDITION_PRINTS_IMAGES = prismicData.prints.map(item => {
    return {
      title: item.image_title.text,
      fullUrl: item.image.url,
      thumbnailUrl: item.image.thumbnails.w400.url,
      orientation: item.image_orientation,
      supplementary_info: item.image_dimensions.text,
      supplementary_info_prefix: '',
      supplementary_info_suffix: '',
      price_formatted: item.price_information.text,
      isAvailable: item.in_stock,
      paypalButtonId: item.paypal_button_id.text,
    }
  })

  return (
    <Layout pageName="limited-edition-prints" documentTitlePrefix={`${prismicData.pageHeading} | Work`}>
      <WorkSubNav
        currentPageName="limited-edition-prints"
        limitedEditionPrintsAreAvailable={prismicData.limitedEditionPrintsAreAvailable}
      />
      <WorkSubHeading headingText={prismicData.pageHeading} />
      <WorkIntroText html={prismicData.introText} />
      <GalleryGrid
        staticImagesFolderName="limited-edition-prints"
        imageData={LIMITED_EDITION_PRINTS_IMAGES}
        classModifier="work"
        imagesAreForSale={true}
        purchaseEmailAddress={prismicData.purchaseEmailAddress}
        purchaseEmailSubjectLine={prismicData.purchaseEmailSubjectLine}
        purchaseMethod={prismicData.purchaseMethod}
        buyNowButtonImageUrl={prismicData.buyNowButtonImageUrl}
      />
      <WorkFooterText html={prismicData.footerText} />
      <ScrollToTop />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    prismicLimitedEditionPrintsPage {
      data {
        currently_available
        purchase_method
        email_address {
          text
        }
        email_subject {
          text
        }
        footer_text {
          html
        }
        intro_text {
          html
        }
        page_heading {
          text
        }
        prints {
          image_orientation
          in_stock
          image_title {
            text
          }
          price_information {
            text
          }
          image_dimensions {
            text
          }
          image {
            url(imgixParams: {q: 70, h: 1400, fit: "scale"})
            thumbnails {
              w400 {
                url(imgixParams: {q: 50})
              }
            }
          }
          paypal_button_id {
            text
          }
        }
        buy_now__button_image_url {
          url
        }
      }
    }
  }
`
